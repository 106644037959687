import React, { useEffect, useState } from "react";

import { LoadingMessage } from "./LoadingMessage";
import { defaultLang, Language, languages, setLanguage } from "../i18n";
import { Theme } from "../element/types";
import request from "../request";
import { getUserId } from "../packages/utils";
import { Button } from "./Button";
import { FilledButton } from "./FilledButton";

interface Props {
  langCode: Language["code"];
  children: React.ReactElement;
  theme?: Theme;
}

export const InitializeApp = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [hasAccessRole, setHasAccessRole] = useState(true);

  useEffect(() => {
    const updateLang = async () => {
      await setLanguage(currentLang);
    };
    const loadUser = async () => {
      const response = await request.get(`/user/${getUserId()}`);
      if (!response.data.data) {
        setHasAccessRole(false);
      }
      setLoading(false);
    };
    const currentLang =
      languages.find((lang) => lang.code === props.langCode) || defaultLang;
    updateLang();
    loadUser();
  }, [props.langCode]);

  return loading ? (
    <LoadingMessage theme={props.theme} />
  ) : hasAccessRole ? (
    props.children
  ) : (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      You do not have access to the whiteboard{" "}
      <a href={import.meta.env.VITE_APP_XTATIC_URL}>Back to Xtatic.Online.</a>
    </div>
  );
};
