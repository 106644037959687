/* eslint-disable @typescript-eslint/no-explicit-any */
export const Base_URL = import.meta.env.VITE_APP_API;

import axios from "axios";

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://devel.xtatic.online'
const service = axios.create({
  withCredentials: true,
  baseURL: Base_URL,
  timeout: 50000,
});

export default service;
